import React from "react";
import moment from "moment";
import "../../styles/components/details_quicksummary.scss"

class QuickSummaryBox extends React.Component {

    getTimeSinceCreation(duration) {
        let years = Math.floor(duration.asYears());
        let months = Math.floor(duration.asMonths()) - years * 12;
        let days = Math.floor(duration.asDays()) - months * 30 - years * 365;

        let daysSinceCreation = "";
        if (years > 0) {
            if (years === 1) daysSinceCreation += `${years} year `;
            else daysSinceCreation += `${years} years `;
        }
        if (months > 0) {
            if (months === 1) daysSinceCreation += `${months} month `;
            else daysSinceCreation += `${months} months `;
        }
        else if (days > 0) {
            if (days === 1) daysSinceCreation += `${days} day`;
            else daysSinceCreation += `${days} days`;
        }
        else daysSinceCreation = "Just recently";

        if (daysSinceCreation !== "Just recently") daysSinceCreation += " ago";

        return daysSinceCreation;
    }

    getChangeCounts() {
        let transfers = 0;
        let internalChanges = 0;
        let externalChanges = 0;
        let damages = 0;

        if (this.props.completeRecordData !== null) {
            for (let i = 0; i < this.props.completeRecordData.length; i += 1) {

                let curr = this.props.completeRecordData[i];
                let _type;

                if (i === 0) {
                    _type = curr.rootRecordData.recordType;
                } else {
                    _type = curr.extData.recordType;
                }

                if (_type === "ownershipTransferConfirmation"
                    || _type === "branchAccepted") {
                    transfers += 1;
                }

                if (_type === "generalExtension") {
                    if (curr.extData.changeType === "Internal") internalChanges += 1;
                    if (curr.extData.changeType === "External") externalChanges += 1;
                    if (curr.extData.changeType === "Damage") damages += 1;
                }
            }
        }

        return {
            transfers: transfers,
            internalChanges: internalChanges,
            externalChanges: externalChanges,
            damages: damages
        }
    }

    renderDisputePercentageInfo() {
        if (this.props.extDisputedPercentage !== null) {
            return (
                <div className={"percentage-disputes icon-text-info"}>
                    <div className={"text-container"}>
                        <p className={"value"}>
                            <img className={"icon"}
                                 src={process.env.PUBLIC_URL + "/details/Disputes.png"} alt={""}
                            />
                            {this.props.extDisputedPercentage}%
                        </p>
                        <p className={"label"}>of updates disputed</p>
                    </div>
                </div>
            );
        }
    }

    renderStats() {
        let rootDate;
        let creationDateDuration;
        let timeSinceRRCreation;

        let purchaseDate;
        let purchaseDateDuration;
        let timeSincePurchase;

        if (this.props.completeRecordData !== null) {
            rootDate = moment(parseInt(this.props.completeRecordData[0].rootRecordData.recordCreationDate));
            purchaseDate = moment(parseInt(this.props.completeRecordData[0].rootRecordData.purchaseDate));
        } else {
            rootDate = moment(this.props.rootRecord.rootRecordData.recordCreationDate);
            purchaseDate = moment(parseInt(this.props.rootRecord.rootRecordData.purchaseDate));
        }

        creationDateDuration = moment.duration(moment().diff(rootDate));
        timeSinceRRCreation = this.getTimeSinceCreation(creationDateDuration);

        purchaseDateDuration = moment.duration(moment().diff(purchaseDate));
        timeSincePurchase = this.getTimeSinceCreation(purchaseDateDuration);

        let changeCounts = this.getChangeCounts();

        let captureType = "Product purchase";
        if (this.props.rootRecord) {
            let pRR = this.props.rootRecord;
            if (pRR.rootRecordData.captureType === "Mnf" && pRR.isClaimable) {
                captureType = "Manufactured";
            } else if (pRR.rootRecordData.captureType === "Stock" && pRR.isClaimable) {
                captureType = "Added to inventory";
            }
        }

        return (
            <div className={"stats"}>
                <div className={"icon-text-info purchase-info"}
                    onClick={() => this.props.onScrollToRR()}
                >
                    <div className={"text-container"}>
                        <p className={"value"}>
                            <img className={"icon"}
                                 src={process.env.PUBLIC_URL + "/details/PurchaseDate.png"} alt={""}
                            />
                            {timeSincePurchase}
                        </p>
                        <p className={"label"}>{captureType}</p>
                    </div>
                </div>

                <div className={"icon-text-info record-activation"}
                     onClick={() => this.props.onScrollToRR()}
                >
                    <div className={"text-container"}>
                        <p className={"value"}>
                            <img className={"icon"}
                                 src={process.env.PUBLIC_URL + "/details/RecordCreationDate.png"} alt={""}
                            />
                            {timeSinceRRCreation}
                        </p>
                        <p className={"label"}>Record activation</p>
                    </div>
                </div>

                <div className={"icon-text-info"} id={"owners"}>
                    <div className={"text-container"}>
                        <p className={"value"}>
                            <img className={"icon"}
                                 src={process.env.PUBLIC_URL + "/details/OwnersIcon.png"} alt={""}
                            />
                            {changeCounts.transfers+1}
                        </p>
                        <p className={"label"}>Owner{(changeCounts.transfers+1) > 1 ? "s" : ""}</p>
                    </div>
                </div>

                <div className={"modifications icon-text-info"}
                     onClick={() => this.props.onScrollToFirstMod()}
                >
                    <div className={"text-container"}>
                        <p className={"value"}>
                            <img className={"icon"}
                                 src={process.env.PUBLIC_URL + "/details/Tools.png"} alt={""}
                            />
                            {changeCounts.internalChanges + changeCounts.externalChanges}
                        </p>
                        <p className={"label"}>Modifications</p>
                    </div>
                </div>

                <div className={"reported-damages icon-text-info"}
                     onClick={() => this.props.onScrolltoFirstDamage()}
                >
                    <div className={"text-container"}>
                        <p className={"value"}>
                            <img className={"icon"}
                                 src={process.env.PUBLIC_URL + "/details/Damages.png"} alt={""}
                            />
                            {changeCounts.damages}
                        </p>
                        <p className={"label"}>Reported damages</p>
                    </div>
                </div>

                {this.renderDisputePercentageInfo()}

            </div>
        );
    }


    render() {
        let mobileSuffix = this.props.forMobile ? "-mobile" : "";

        return (
            <div className={"record-summary" + mobileSuffix}>
                <p id={"name"}>Quick Summary</p>
                {this.renderStats()}
            </div>
        );
    }
}

export default QuickSummaryBox;